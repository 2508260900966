import React, { Component } from 'react';
import { StyleSheet, View } from 'react-native';

import SelectModal from "./UI/SelectModal";
import Sheet from "./classes/Sheet";
import helpers, {query, leafCount, leafLoop} from "../helpers";
const colors = helpers.appColors;
import WindowDimensions from "./classes/Dimensions";
const { width, height } = WindowDimensions;

export default class EditObsPage extends Component {
    state = {
    };
    
    // Count leafes
    

    setSelector(d, callback) {
        this.props.showContent(<SelectModal 
            show={true}
            data={d.data}
            multi={d.multi}
            hide={(data) => {
                this.props.showContent(null, false);
                callback(data);
            }}
        />);
    }

    answeredPercentage(target: Sheet)
    {
        let answeredAmount = 0;
        let questionCount = 0;

        leafLoop(target, (question) => {
            if(question.visible && !["notNeeded"].includes(question.type))
            {
                if(question.response.length > 0)
                {
                    answeredAmount++
                }
                questionCount++;
            }
        }, ["notNeeded"]);
        const ret = answeredAmount / questionCount;
        return Number.isNaN(ret) ? 0 : ret;
    }

    componentDidMount()
    {
        const target = this.props.sheet;
        this.props.setProgress(this.answeredPercentage(target));
        this.props.sheet.on("response", async () => {
            query("UPDATE sheetData SET content = ? WHERE id = ?", this.props.db, [
                target.toJSON(),
                target.id,
            ]);

            this.forceUpdate();
            // questionAmount = leafCount(target, (question) => question.visible ? 1 : 0);
            // answeredAmount = this.answeredAmount(target);
            // console.log(answeredAmount, "/", questionAmount, "==", answeredAmount / questionAmount);
            // this.props.setProgress(answeredAmount / questionAmount);
            this.props.setProgress(this.answeredPercentage(target));


        });
    }

    render(){
        return (<React.Fragment>
            <View style={[styles.bg, helpers.appStyles.boxShadow, {paddingBottom: 50}]}>
                {
                    this.props.sheet.render(this.props.showContent, this.props.showModal, this.props.showCam, this.props.getLocation)
                }
            </View>
          </React.Fragment>);
    }
}

const styles = StyleSheet.create({
    box: {
        width: "90%",
        borderRadius: 15,
        marginLeft: "5%",
        marginTop: 20,
        backgroundColor: "#FFF",
        padding: 15,

    },

    topTitle: {
        fontSize: 29,
        color: colors.white,
        paddingBottom: 5,
    },
    topTitleSub: {
        fontSize: 15,
        color: colors.white,
        paddingBottom: 6,
    },
    bg: {
        backgroundColor: colors.bgGrey,
        width: "100%",
        height: "100%",
     },
     topGradient: {
       width: "100%",
       height: 151,
       justifyContent: "flex-end",
       padding: 15,
     },
});
