import React, {useState} from 'react';
import { StyleSheet, Text, View, Image } from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';

import CreditsAnimator from "./CreditsAnimator";
import {appColors as appColorss, appStyles, IP} from "../../helpers";

export default function GradientHeader(props){
    const appColors = {
        ...appColorss,
        ...props.userData.colors,
    };
    const [tempPoints, setTempPoints] = useState(0);
    return (<LinearGradient
        start={props.headerTitle ? [0.5, 0] : [0,0]}
        colors={[appColors.orange, appColors.red]}
        style={[
            styles.topGradient, 
            props.headerTitle ? {} : {padding: 15},
        ]}
    >
        {
            props.headerTitle && <View style={[appStyles.inlineFlex, styles.topContainer]}>
                <View style={[styles.avatarContainer, appStyles.inlineFlex]}>
                    <Image style={styles.avatar} resizeMode="cover" source={props.userData.pb ? {uri: `${IP}/image/${props.userData.pb}`} : require('../../assets/avatar.png')}/>
                    <Text style={styles.nameText}>
                        {props.userData.name || ""}
                    </Text>
                </View>
                
                <View style={[styles.creditsContainer, appStyles.inlineFlex]}>
                    <Text style={styles.creditsText}>
                        {props.points || 0}
                    </Text>
                    <View style={[styles.animatedCredits, styles.credits, {position: "relative"}]}/>
                    <CreditsAnimator amount={tempPoints} callback={props.pointCallback}
                    animator={props.pointAnimator}
                    />
                </View>
            </View>
        }
        {
            props.headerTitle && <Text style={styles.headerText}>{props.headerTitle}</Text>
        }
        {
            props.title &&
            <Text style={styles.topTitle}>
                {props.title}
            </Text>
        }
        {
            props.subTitle && 
            <Text style={styles.topTitleSub}>
                {props.subTitle}
            </Text>
        }
        {props.children}
    </LinearGradient>);
}



const styles = StyleSheet.create({
    avatarContainer: {

    },
    topContainer: {
        width: "100%",
        height: 30,
        marginTop: 5,
        top: 40,

        justifyContent: "space-between",

        paddingLeft: 15,
        paddingRight: 15,
        zIndex: 1,
        position: "absolute",
    },
    avatar: {
        height: 25,
        width: 25,
        borderRadius: 12.5,
        borderWidth: 0.3,
        borderColor: "#FFF",
        backgroundColor: "#000",
    },
    creditsContainer: {
    },
    credits: {
        height: 10,
        width: 10,
        marginLeft: 5,
    },
    animatedCredits: {
        position: "absolute", 
        zIndex: 999,
        
        
        backgroundColor: appColorss.darkOrange,
        borderColor: "yellow",
        borderWidth: 2,
        borderRadius: 7.50,
        height: 7,
        width: 7,
    },
    creditsText: {
        color: appColorss.white,
        fontWeight: "bold",
        fontSize: 12, 
    },
    nameText: {
        fontSize: 12,
        color: appColorss.white,
        fontWeight: "bold",
        paddingLeft: 10,
    },





    box: {
        width: "90%",
        borderRadius: 15,
        marginLeft: "5%",
        marginTop: 20,
        backgroundColor: "#FFF",
        padding: 15,

    },
    
    headerText: {
        color: appColorss.white,
        fontSize: 28,
        fontWeight: "bold",
        padding: 15,
        paddingTop: 37,
        paddingBottom: 21,
    },

    topTitle: {
        fontSize: 29,
        color: appColorss.white,
        paddingBottom: 5,
    },
    topTitleSub: {
        fontSize: 15,
        color: appColorss.white,
        paddingBottom: 6,
    },
    bg: {
        backgroundColor: appColorss.bgGrey,
        width: "100%",
        height: "100%",
     },
     topGradient: {
       width: "100%",
       height: 151,
       justifyContent: "flex-end",
       
     },
});
