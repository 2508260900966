import * as Localization from 'expo-localization';
import i18n from 'i18n-js';

// Set the key-value pairs for the different languages you want to support.
i18n.translations = {
  "da-DK": {
    "Daglige handlinger": "Daglige handlinger",
    "point": "point",
    "WRONG_PASS_USR": "Forkert brugernavn, eller kodeord.",
    "Email": "Email",
    "Password": "Password",
    "Sign in": "Sign in",
    "Sign up": "Sign up",
    "Forgot your password?": "Forgot your password?",
    "Code not found": "Code not found",
    "Invitation code": "Invitation code",
    "Code": "Code",
    "Continue": "Continue",
    "SIGNUP_TERMS": "By signing up, you agree to our Terms and Conditions of use.",
    "First name": "First name",
    "Last name": "Last name",
    "Phone": "Phone",
    "Confirm password": "Confirm password",
    "The email is not valid": "The email is not valid",
    "All information must be filled": "All information must be filled",
    "The given passwords must be the same": "The given passwords must be the same",
    "UNKOWN_ERROR": "An unkown error occurred. Contact an administrator if this problem persists.",
    "CANCEL": "Annuller",
    "DELETE": "Slet",
    "DELETE_OBS": "Vil du slette den uden at indsende dataen?",
    "Ingen igangværende rapporter! Du kan åbne en ny ovenover": "Ingen igangværende rapporter! Du kan åbne en ny oven over.",
    "": "",
  },
  "en-US": {
    "Daglige handlinger": "Daglige handlinger",
    "point": "point",
    "WRONG_PASS_USR": "Forkert brugernavn, eller kodeord.",
    "Email": "Email",
    "Password": "Password",
    "Sign in": "Sign in",
    "Sign up": "Sign up",
    "Forgot your password?": "Forgot your password?",
    "Code not found": "Code not found",
    "Invitation code": "Invitation code",
    "Code": "Code",
    "Continue": "Continue",
    "SIGNUP_TERMS": "By signing up, you agree to our Terms and Conditions of use.",
    "First name": "First name",
    "Last name": "Last name",
    "Phone": "Phone",
    "Confirm password": "Confirm password",
    "The email is not valid": "The email is not valid",
    "All information must be filled": "All information must be filled",
    "The given passwords must be the same": "The given passwords must be the same",
    "UNKOWN_ERROR": "An unkown error occurred. Contact an administrator if this problem persists.",
    "CANCEL": "Annuller",
    "DELETE": "Slet",
    "DELETE_OBS": "Vil du slette den uden at indsende dataen?",
    "Ingen igangværende rapporter! Du kan åbne en ny ovenover": "Ingen igangværende rapporter! Du kan åbne en ny oven over.",
    "": "",
  },
  en: {},
  ja: {},
};

// console.log("Hmmm", Localization.locale);
// Set the locale once at the beginning of your app.
i18n.locale = Localization.locale;
i18n.defaultLocale = "en-US";
// When a value is missing from a language it'll fallback to another language with the key present.
i18n.fallbacks = "en-US";

export default i18n;