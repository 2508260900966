import React, { useState } from 'react';
import { StyleSheet, Animated, Easing } from 'react-native';
import { interpolate } from "flubber";
import { tween, easing } from 'popmotion';
import Svg, { G, Defs, Path, Use, Stop, ClipPath, LinearGradient } from 'react-native-svg';


const searchPath = `M17.367,19h0l-5.393-5.442v-.842l-.346-.3a6.921,6.921,0,0,1-4.6,1.682,6.8,6.8,0,0,1-4.972-2.053A6.8,6.8,0,0,1,0,7.076a6.884,6.884,0,0,1,2.054-5A6.705,6.705,0,0,1,6.977,0a6.762,6.762,0,0,1,4.972,2.078,6.883,6.883,0,0,1,2.053,5,6.924,6.924,0,0,1-1.682,4.6l.347.247h.891L19,17.367,17.368,19ZM7.026,2.177A4.657,4.657,0,0,0,3.587,3.612a4.782,4.782,0,0,0-1.41,3.464,4.734,4.734,0,0,0,4.849,4.848,4.783,4.783,0,0,0,3.463-1.409,4.659,4.659,0,0,0,1.435-3.439,4.74,4.74,0,0,0-1.435-3.464A4.739,4.739,0,0,0,7.026,2.177Z`;
const loadingSvg = `M0,0C.212,6.791,6.159,12.467,13.282,12.679S26.009,7.558,25.8.768c.238,7.639-5.343,13.66-12.467,13.449S.238,7.639,0,0`;
var interpolator = interpolate(searchPath, loadingSvg);
function doAnimation(setVal)
{
    /*time += timeInt;
    if(time < 1)
    {
        setVal(interpolator(time));
        requestAnimationFrame(() => doAnimation(interpolator, setVal, time));
    }else{
        setVal(interpolator(1));
    }*/
    tween({
        duration: 300,
        from: { i: 0 },
        to: { i: 1 }
      })
        .pipe(({ i }) => ({ path: interpolator(i) }))
        .start(({ path }) => {
            setVal(path);
        })
}

interface Props {
    animate: boolean,
}

let animating: boolean = false;
export default function SearchIcon(props: Props){
    const [rotation] = useState(new Animated.Value(0));
    const [currPath, setPath] = useState(interpolator(0));

    if(!animating && props.animate)
    {
        animating = true;
        doAnimation(setPath);
        Animated.sequence([
            Animated.delay(300),
            Animated.loop(
                Animated.timing(
                    rotation,
                    {
                        toValue: 1,
                        duration: 500,
                        easing: Easing.linear,
                        useNativeDriver: true
                    }
                )
            )
        ]).start();
    }

    const spin = rotation.interpolate({
        inputRange: [0, 1],
        outputRange: ['0deg', '360deg']
      });
    return (<Animated.View style={{transform: [{rotate: spin}] }}>
        <Svg width={30} height={30} viewBox="-5 0 30 18" style={styles.svgContainer}>
    <G>
      <Path d={currPath} fill={"#000"} />
    </G>
  </Svg>
  </Animated.View>);
};

const styles = StyleSheet.create({
    svgContainer: {
    },
});