import * as SecureStore from 'expo-secure-store';
import {Platform} from "react-native";

if(!window)
{
    console.log("OVerwriting localstorage");
    localStorage = {
        setItem: (a: string, b: string) => {},
        getItem: (a: string) => {},
    };
}

class SecureStoreWeb {
    static setItemAsync(key: string, value: string)
    {
        return new Promise(r => {
            r(localStorage.setItem(key, value));
        })
    }

    static getItemAsync(key: string)
    {
        return new Promise(r => {
            r(localStorage.getItem(key));
        });
    }

    static deleteItemAsync(key: string)
    {
        return new Promise(r => {
            r(localStorage.removeItem(key));
        });
    }
}


export default Platform.OS === "web" ? SecureStoreWeb : SecureStore;