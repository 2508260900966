import React, { useState } from 'react';
import { StyleSheet, Text, View, TouchableOpacity, Platform } from 'react-native';
import { FontAwesome as Icon  } from '@expo/vector-icons';
import { LinearGradient } from 'expo-linear-gradient';

import helpers from "../../helpers";

interface Props {
    title: string,
    onPress(): void,
    colorSet: string,
}

const colors = helpers.appColors;
export default function GradientButton(props: Props){
    const isWeb = Platform.OS === "web";
    return (<View style={[styles.btnContainer, !isWeb && styles.shadow]}>
        <TouchableOpacity onPress={() => props.onPress()} activeOpacity={0.65}>
            <LinearGradient
                colors={props.colorSet === "blue" ? [colors.grey, colors.lightGrey] : [colors.lightOrange, colors.orange]}
                style={[styles.btn, isWeb && styles.shadow]}
                start={[1,1]}
                end={[0,0]}
            >
                <Text style={styles.btnText}>{props.title}</Text>
            </LinearGradient>
        </TouchableOpacity>
      </View>);
};

const styles = StyleSheet.create({
    shadow: {

         shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 10,
        },
        shadowOpacity: 0.2,
        shadowRadius: 16.00,
    },
    btnContainer: {
       
        width: "100%",
        padding: 10,

      },
      btn: {
        width: "100%",
        height: 60,
        borderRadius: 35,
        
    
        justifyContent: "center",
        alignItems: "center",
    
      },
      
    btnText: {
        fontSize: 18,
        color: "#FFF"
    },
});