import React, { useState, useRef } from 'react';
import { StyleSheet, Text, View, Alert, TextInput, TouchableOpacity, KeyboardAvoidingView, Platform, Image, Animated, Easing } from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';

import Logo from "./UI/Logo";
import AnimatedButton from "./UI/AnimatedButton";

import Lang from "./locale";
import helpers, {api, IS_WEB} from "../helpers";
import platformKeys from "../platformHelpers";

import WindowDimensions from "./classes/Dimensions";
const { width, height } = WindowDimensions;

const colors = helpers.appColors;

export default function LoginPage(props) {
    let username = "";
    let password = "";
    const [backAnimation] = useState(new Animated.ValueXY());
    Animated.loop(Animated.sequence([
      Animated.timing(
        backAnimation, 
        {
          duration: 8000,
          useNativeDriver: true,
          toValue: {x: 200, y: -150}
        }, 
      ),
      Animated.timing(
        backAnimation, 
        {
          duration: 10000,
          useNativeDriver: true,
          toValue: {x: -200, y: 0}
        }, 
      ),
      Animated.timing(
        backAnimation, 
        {
          duration: 10000,
          useNativeDriver: true,
          toValue: {x: 0, y: 0}
        }, 
      ),
    ])).start();
    let loading = false;
    const signIn = async () => {
      if(!loading)
      {
        loading = true;
        const data = await api("signIn", {
          username: username || "",
          password: password || "",
        });
        console.log("Got data", data, usr.current, platformKeys.value, usr.current, {
          username: username || "",
          password: password || "",
        });
        loading = false;
        if(data && !data.error)
        {
          // if(data.data.lang && Lang.translations[data.data.lang])
          // {
          //   Lang.locale = data.data.lang;
          // }
          
          if(data.data.verify)
          {
            api("verifyUsr", {}, data.data.t).then(d => console.log("Verify", d));
            props.navigation.push("Verify", {
              token: data.data.t,
  
              userData: {
                  token: data.data.t,
                  points: data.data.points,
                  pb: data.data.pb,
                  name: data.data.name,
                  department: data.data.department,
                  phone: data.data.phone,
                  verifyData: data.data.verifyData,
                  verify: data.data.verify,
                  colors: data.data.colors,
              },
              groups: data.data.groups,
              ...props.route.params,
            });
          }else{
            // props.route.params.login(data.t);/
            props.route.params.setUserData({
              token: data.data.t,
              points: data.data.points,
              pb: data.data.pb,
              name: data.data.name,
              department: data.data.department,
              phone: data.data.phone,
              verifyData: data.data.verifyData,
              verify: data.data.verify,
              colors: data.data.colors,
            }, data.data.groups);
          }
        }else{
          if(btn.current)
          {
            btn.current.stopLoad();
          }
          if(data.data && data.data.disabled)
          {
            Alert.alert("Safeallday", data.data.message);
          }else{
            Alert.alert("Safeallday", Lang.t('WRONG_PASS_USR'));
          }
        }
      }
      //props.route.params.login(true)
    };

    const whatToFocus = () => {
      if(username.length === 0)//usr.current && (!usr.current[platformKeys.value] || usr.current[platformKeys.value].length === 0))
      {
        usr.current.focus();
      }else if(password.length === 0)//pass.current && (!pass.current[platformKeys.value] || pass.current[platformKeys.value].length === 0))
      {
        pass.current.focus();
      }else if(btn.current){
        btn.current.startLoad();
      }
    }
    const btn = useRef(false);
    const usr = useRef(false);
    const pass = useRef(false);

    // console.log("Loing", props);
    //colors.lightBlue, colors.darkBlue
  return (
      <LinearGradient
        colors={[colors.lightBlue, colors.darkBlue]}
        style={styles.bg}
        key={props.localeKey}
      >
        <KeyboardAvoidingView 
          behavior={Platform.OS == "ios" ? "position" : "height"}
          keyboardVerticalOffset={10}
          contentContainerStyle={styles.keyboardFix}
        >
          <View style={{paddingBottom: 50, alignItems: "center", zIndex: 9}}>
            <Logo width={200} type={1}/>
            <Text style={{fontSize: 45, color: "#FFF", fontFamily: "System", fontWeight: "bold"}}>Safeallday</Text>
          </View>
            
          <Animated.View style={{transform: backAnimation.getTranslateTransform()}}>
            <LinearGradient
                      colors={[colors.lightBlue, colors.darkBlue]}//[colors.lightOrange, colors.red]}
                      style={[styles.beutyShit, {opacity: 0.5}]}
                      start={[0,0]}
                      end={[1,1]}
                      />
          </Animated.View>
          <View style={styles.container} onLayout={() => {
            if(usr.current && props.route.params.mail)
            {
              username = props.route.params.mail;
              //usr.current[platformKeys.value] = props.route.params.mail;
            }
            if(pass.current && props.route.params.pass)
            {
              password = props.route.params.pass;
              // pass.current[platformKeys.value] = props.route.params.pass;
            }
            if(btn.current && usr.current && props.route.params.mail && pass.current && props.route.params.pass)
            {
              btn.current.startLoad();
            }
          }}>

              <View style={styles.input}>
                  <TextInput
                      ref={usr}
                      style={styles.innerInput}
                      placeholder={Lang.t("Email")}
                      autoCompleteType="email" 
                      defaultValue={props.route.params.mail || ""}
                      onChangeText={val => username = val}
                      onSubmitEditing={whatToFocus}
                  />
              </View>
              <View style={styles.input}>
                  <TextInput
                      ref={pass}
                      style={styles.innerInput}
                      placeholder={Lang.t("Password")}
                      autoCompleteType="password" 
                      secureTextEntry={true}
                      defaultValue={props.route.params.pass || ""}
                      onChangeText={val => password = val}
                      onSubmitEditing={whatToFocus}
                  />
              </View>

              <AnimatedButton title={Lang.t("Sign in")} width={width * 0.8} onPress={signIn} ref={btn}/>
              {/* <AnimatedButton title={Lang.t("Sign in")} width={width * 0.8} onPress={signIn} ref={btn}/> */}
            
              {/* <TouchableOpacity onPress={() => alert("Hello man")} style={styles.forgotPass} activeOpacity={0.45}>
                  <Text style={styles.smallText}>{Lang.t("Forgot your password?")}</Text>
              </TouchableOpacity> */}

        
              <View style={[styles.bottom, ["web", "android"].includes(Platform.OS) ? {} : {}]}>
                <TouchableOpacity onPress={() => props.navigation.push("Invitation")} activeOpacity={0.65} style={{width: width * 0.8}}>
                    <LinearGradient
                        colors={[colors.otherWhite, colors.white]}
                        style={styles.btn}
                        start={[0,0]}
                        end={[1,1]}
                    >
                        <Text style={styles.signUpText}>{Lang.t("Sign up")}</Text>
                    </LinearGradient>
                </TouchableOpacity>
              </View>
          </View>
        </KeyboardAvoidingView>
    </LinearGradient>
  );
}

const styles = StyleSheet.create({
  keyboardFix: {
    width, 
    alignItems: "center",
  },

  bottom: {
    // position: "absolute",
    // top: height - 150,
    // zIndex: 99999,
    marginTop: 10,
    width: "100%", 
  },

  btn: {
    width: "100%",
    height: 60,
    // marginTop: Platform.OS == "web" ? 0 : 0,
    // marginTop: 80,
    borderRadius: 15,

    justifyContent: "center",
    alignItems: "center",

  },

    beutyShit: {
        position: "absolute",
        top: "70%",
        left: 0,
        width,
        zIndex: 0,
        height: height,

        backgroundColor: "red",
        transform: [{ rotate: "45deg" }],
    },
    forgotPass: {
      width: width * 0.8,
      marginTop: 16,
      justifyContent: "center",
      alignItems: "center",
    },
    smallText: {
        fontSize: 16,
        color: "#FFF"
    },
    signUpText: {
        fontSize: 18,
        color: "#000"
    },
    btnText: {
        fontSize: 18,
        color: "#FFF"
    },
  bg: {
      width: "100%",
      height: "100%",


      flex: 1,
      justifyContent: "center",
      alignItems: "center"
  },
  
  container: {
    width: IS_WEB ? "100%" : "80%",
    zIndex: 9,
  },
  innerInput: {
    height: "100%", 
    width: "100%",

    padding: 5,
    paddingLeft: 15,
    paddingRight: 15,
    
  },
  input: {
    backgroundColor: "#FFF",
    borderRadius: 15,
    height: 55,
    width: width * 0.8,// "100%",
    marginTop: 15,

    shadowColor: "#000",
    shadowOffset: {
        width: 0,
        height: 0,
    },
    shadowOpacity: 0.15,
    shadowRadius: 16.00,

    elevation: 24,
  },
});
