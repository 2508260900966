import {Platform} from "react-native";

export function getValue(input)
{
    return Platform.OS === "web" ? input.value : input._lastNativeText;
}
export function getValueKey()
{
    return Platform.OS === "web" ? "value" : "_lastNativeText";
}
export function setValue(input, value)
{
    input[Platform.OS === "web" ? "value" : "_lastNativeText"] = value;
}

const platformKeys = {
    value: Platform.OS === "web" ? "value" : "_lastNativeText"
};

export default platformKeys;