import React, { useState, useRef } from 'react';
import { StyleSheet, Text, View, Platform, ScrollView, TextInput, TouchableOpacity, Animated, Image, Button, ActivityIndicator, KeyboardAvoidingView } from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';
import MapView, { Marker, PROVIDER_GOOGLE } from 'react-native-maps';
import { FontAwesome as Icon } from '@expo/vector-icons';

import * as ImagePicker from 'expo-image-picker';
import Constants from 'expo-constants';
import * as Permissions from 'expo-permissions';

import SignatureScreen from 'react-native-signature-canvas';
import helpers from "../../helpers";
import SelectModal from "./SelectModal";
import SearchIcon from "./SearchIcon";
import WindowDimensions from "../classes/Dimensions";

const colors = helpers.appColors;
const { width, height } = WindowDimensions;

async function getPermissionAsync() {
    if (Constants.platform?.ios) {
      const { status } = await Permissions.askAsync(Permissions.CAMERA_ROLL);
      if (status !== 'granted') {
        alert('Sorry, we need camera roll permissions to make this work!');
      }
    }
  }

 async function _pickImage() {
    try {
        let permissionResult = await ImagePicker.requestCameraRollPermissionsAsync();

        if(permissionResult.granted === false) {
            alert("Permission to access camera roll is required!");
            return;
        }
        let result = await ImagePicker.launchImageLibraryAsync({
            mediaTypes: ImagePicker.MediaTypeOptions.All,
            allowsEditing: false,
            base64: true,
            aspect: [4, 3],
            quality: 0.7,
        });
        if (!result.cancelled) {
            //this.setState({ image: result.uri });
            return `data:image/jpg;base64,${result.base64}`;//result.uri;
        }

    } catch (E) {
    }

    return false;
  }


function extractAnswers(data, checked:boolean = true)
{
    return data.reduce((a, b) => {
        const newChecked = b.data.filter(d => d.checked === checked);
        return [...a, ...newChecked]
    }, []);
}

function getComponentType(type: string, props, value, setValue)
{
    const IS_WEB = Platform.OS === "web";
    switch(type)
    {
        case "range":
            return (<View style={styles.rangeContainer}>
                {
                    [...Array(props.to - props.from + 1)].map((d, i) => {
                        const TARGET_VAL = i + props.from;
                        return (<React.Fragment key={i}>
                            {
                                i > 0 ?
                                    <View style={styles.rangeSpacer} />
                                : null
                            }
                            <TouchableOpacity style={[styles.circleBtn, i === value ? styles.circleBtnSelected : null]} onPress={() => {
                                setValue(i);
                                
                            }}>
                                <Text style={[styles.circleBtnText, i === value ? styles.circleBtnTextSelected : null]}>{TARGET_VAL}</Text>
                            </TouchableOpacity>
                        </React.Fragment>);
                    })
                }
            </View>);
        break;
        //<Icon name={"search"} size={20} color="#000" />
        case "addressInput":
            const [isLoading, setLoading] = useState(false);
            return (<View style={styles.rangeContainer}>
                    <View style={styles.inputContainer}>
                        <TextInput style={styles.input} value="Roarsvej"/>
                        <SearchIcon animate={isLoading} />
                    </View>
                    <Button title="test" onPress={() => setLoading(true)}/>
                    <View style={[styles.inputSuggestions, helpers.appStyles.boxShadow]}>
                        <LinearGradient start={[0,0]} colors={[colors.orange, colors.red]} style={styles.suggestionGradient} />
                        <ScrollView>
                            {
                                [...Array(4)].map((d, i) => <View style={styles.suggestion} key={i}>
                                    <View style={styles.suggestionInfo}>
                                        <Text style={styles.suggestionAddr}>
                                            Roarsvej 28
                                        </Text>
                                        <Text style={styles.suggestionCity}>
                                            Frederiksberg
                                        </Text>
                                    </View>
                                    <Image source={require("./assets/arrow.png")} style={styles.suggestionIcon}/>
                                </View>)
                            }
                        </ScrollView>
                    </View>
            </View>);
        break;
        case "radio":
            {
                const [curr, setCurr] = useState(props.data);
                const selected = curr[0].data.filter(d => d.checked);
                return (<React.Fragment>
                    <TouchableOpacity onPress={() => props.showSelector({
                        show: true,
                        multi: true,
                        data: curr,
                    }, (vals) => {
                        ///extractAnswers
                        // console.log("Hmmm", vals, extractAnswers(vals));
                        setCurr(vals);
                        setValue(vals);
                    })} style={styles.btn}>
                        <Text>{selected.length > 0 ? selected.map(d => d.name).join(", ") : "Tryk for at vælge valgmuligheder"}</Text>
                    </TouchableOpacity>
                </React.Fragment>)
            }
        break;
        case "select":
            {
                const [curr, setCurr] = useState(props.data);
                const selected = extractAnswers(curr);//curr[0].data.filter(d => d.checked);
                return (<React.Fragment>
                    <TouchableOpacity onPress={() => props.showSelector({
                        show: true,
                        multi: false,
                        data: curr,
                    }, (vals) => {
                        // console.log("Got data", vals);
                        setCurr(vals);
                        setValue(vals);
                    })} style={styles.btn}>
                        <Text>{selected.length > 0 ? selected.map(d => d.name).join(", ") : "Tryk for at vælge én valgmulighed"}</Text>
                    </TouchableOpacity>
                </React.Fragment>)
            }
        break;
        case "upload": // TODO: Internet check
            {
                const [images, setImages] = useState([]);
                const handleImage = (newImage:string) => {
                    console.log("Handleimage", newImage);
                    const targetI = images.length;
                    setImages([
                        ...images,
                        targetI,
                    ]);

                    helpers.uploadImage(newImage).then(url => {
                        url = newImage; // TEMP WHILE IT DOSENT UPLOAD
                        const newImages = [...images];
                        newImages[targetI] = url;
                        setImages(newImages);
                    });
                };
                return (<React.Fragment>
                    <View style={styles.rangeContainer}>
                        <TouchableOpacity onPress={async () => {
                            const newImage = await _pickImage();
                            if(newImage)
                            {
                                handleImage(newImage);
                            }
                        }} style={[styles.greyBtn, {borderRightWidth: 0.5, borderTopRightRadius: 0, borderBottomRightRadius: 0}]}>
                            <Icon name="photo" size={18} color="#000" />
                        </TouchableOpacity>
                        <TouchableOpacity disabled={IS_WEB} onPress={async () => {
                            // console.log(props);
                            // props.navigation.push("Cam");
                            // const newImage = await _pickImage(); // TODO: use camera-component
                            // if(newImage)
                            // {
                            //     handleImage(newImage);
                            // }
                            if(!IS_WEB)
                            {
                                props.showCam(true, (data) => {
                                    handleImage(`data:image/jpg;base64,${data.base64}`);
                                    console.log("Got data", data);
                                });
                            }
                        }} style={[styles.greyBtn, {borderLeftWidth: 0.5, borderTopLeftRadius: 0, borderBottomLeftRadius: 0}, IS_WEB ? {opacity: 0.5} : {}]}>
                            <Icon name="camera" size={18} color="#000" />
                        </TouchableOpacity>
                    </View>
                    {
                        images.length > 0 || 1?
                            <View style={{height: 60, flexDirection: "row"}}>
                                {
                                    images.map((image, i) => typeof image === "number" ? <View key={i} style={{height: 55, width: 55, backgroundColor: helpers.appColors.bgGrey, justifyContent: "center", alignItems: "center"}}>
                                        <ActivityIndicator />
                                    </View> : <Image source={{ uri: image }} key={i} style={{ width: 55, height: 55 }} />)
                                }
                            </View>
                        : null
                    }
                </React.Fragment>);
            }
        break;
        case "textInput":
            return (<View style={styles.rangeContainer}>
                        <View style={styles.inputContainer}>
                            <TextInput style={styles.input}/>
                        </View>
                </View>);
        break;
        case "input":
            {
                const input = useRef(false);            
                return (<View style={styles.rangeContainer}>
                        <View style={styles.inputContainer}>
                            <TextInput ref={input} style={styles.input} onBlur={(e) => setValue(input.current?._lastNativeText)}/>
                        </View>
                </View>);
            }
            break;
        case "multilineInput":
        {
            const input = useRef(false);            
            return (<View style={styles.rangeContainer}>
                        <KeyboardAvoidingView behavior="padding" enabled={true} style={{width: "100%"}}>
                            <View style={[styles.inputContainer, {height: 80}]}>
                                <TextInput ref={input} style={styles.input} multiline={true} onBlur={(e) => setValue(input.current?._lastNativeText)}/>
                            </View>
                        </KeyboardAvoidingView>
                </View>);
            break;
        }
        case "numberInput":
            return (<View style={styles.rangeContainer}>
                        <View style={styles.inputContainer}>
                            <TextInput style={styles.input} keyboardType="number-pad"/>
                        </View>
                </View>);
            break;
        case "draw": // TODO: Check internet connection
        // TODO: Use a modal for this content
            return <View style={{height: 200}}>
                        <SignatureScreen
                        descriptionText="Underskriv"
                        clearText="Nulstil"
                        confirmText="Gem"
                        />
                    </View>
            return (<TouchableOpacity style={styles.btn}>
                    <Text>Underskriv</Text>
                    
                </TouchableOpacity>);
        break;
        case "mapPicker":
            const [marker, setMarker] = useState({
               latitude: 0,
               longitude: 0, 
            });
            return (<MapView 
                provider={PROVIDER_GOOGLE}
                style={styles.map} 
                onPress={event => setMarker(event.nativeEvent.coordinate)}
                initialRegion={{
                latitude: 37.78825,
                longitude: -122.4324,
                latitudeDelta: 0.0922,
                longitudeDelta: 0.0421,
                }}
                >
                        <Marker
                        coordinate={marker}
                        />
              </MapView>);
            break;
        case "boolean":
                return (<View style={[styles.rangeContainer]}>
                            {
                                (props.answers || ["Ja", "Hallo måske da"]).map((d, i) => {
                                    return (<React.Fragment key={i}>
                                        {
                                            i > 0 ?
                                                <View style={styles.rangeSpacer} />
                                            : null
                                        }
                                        <TouchableOpacity style={[styles.circleBtn, i === value ? styles.circleBtnSelected : null]} onPress={() => {
                                            setValue(i);
                                        }}>
                                            <Text style={[styles.circleBtnText, i === value ? styles.circleBtnTextSelected : null]}>{d}</Text>
                                        </TouchableOpacity>
                                    </React.Fragment>);
                                })
                            }
                    </View>);
                break;
        default:
            return (<Text>{type} not found!</Text>);
        break;
    }
}


function drawBox(props, anim, value, setValue, hidden = false, boxes)
{
    return (<Animated.View style={[styles.box, props.onlyRaw ? {padding: 0, width: "100%", marginLeft: 0, backgroundColor: "transparent", borderRadius: 0} : {}, helpers.appStyles.boxShadow, {shadowOpacity: anim.shadow}, hidden ? {position: "initial", opacity: 0} : null, {zIndex: anim.zIndex, transform: [{ scale: anim.scale }, ...anim.transform.getTranslateTransform()]}]}>
        <View style={styles.titleContainer}>
            <Text style={styles.title}>
                {props.text}
            </Text>
            {
                boxes.length > 1 ?
                    <View style={styles.titleBtns}>
                        <TouchableOpacity style={styles.titleBtn} onPress={() => Animated.parallel(animateBoxes(boxes, false)).start()}>
                            <Icon name="arrow-circle-left" size={18} color="#000" />
                        </TouchableOpacity>
                        <TouchableOpacity style={styles.titleBtn} onPress={() => Animated.parallel(animateBoxes(boxes, true)).start()}>
                            <Icon name="arrow-circle-right" size={18} color="#000" />
                        </TouchableOpacity>
                    </View>
                : null
            }
        </View>
        {/* <Text style={styles.desc}>
            {props.text}
        </Text> */}

        {
            getComponentType(props.type, props, value, setValue)
        }
    </Animated.View>);
}

function showSubBoxes(boxes):any[] {
    return boxes.map((box, i) => {
        const  newI = (boxes.length - 1) - box.zIndex._value;
         return [
             newI === 0 ? Animated.delay(0) : Animated.timing(box.transform, {
                 toValue: {
                     x: 0,
                     y: animationInfo.y.func(newI),
                 },
                 useNativeDriver: false,
                 duration: 300,
             }),
             Animated.timing(box.scale, {
                 toValue: animationInfo.scale.func(newI),
                 duration: 300,
                 useNativeDriver: false,
             }),
             Animated.timing(box.shadow, {
                 toValue: 0.08,
                 duration: 300,
                useNativeDriver: false,
             })
         ];
     }).reduce((a, b) => [...a, ...b], []);
}

const animationInfo = {
    isAnimating: false,
    scale: {
        func: (x: number) => -1 * ((13 * x) / 100) + 1,
    },
    y: {
        func: (x: number) => (-18) * x,
    },
};

export interface Props {
    onlyRaw: boolean,
    onAnswer(val: number): void,
    boxes: [],
    text: string,
    type: string,

    from?: number,
    to?: number,

    answers?: [string],
};

export default function QuestionBox(props: Props) {
    const extras = props.boxes || ["a"];
    const [boxes] = useState(
        extras.map((d, i) => ({
           scale: new Animated.Value(1), 
           transform: new Animated.ValueXY({x: 0, y: 0}), 
           zIndex: new Animated.Value(i),
           shadow: new Animated.Value(0.032), //0.08
        }))
    );
    let boxesShowing: boolean = false;
    const [value, setValue] = useState(null);

  return (
      <View style={props.onlyRaw ? null : styles.container}>
          {drawBox(props, boxes[0], value, props.onAnswer, true, boxes)}
        {
            [...Array(boxes.length)].map((d, i, arr) => <React.Fragment key={i}>
                {drawBox(props, boxes[boxes.length - i - 1], value, (val) => {
                    if(animationInfo.isAnimating)
                    {
                        return;
                    }
                    if(props.onAnswer)
                    {
                        props.onAnswer(val);
                    }
                    setValue(val);
                    if(boxes.length > 1)
                    {
                        animationInfo.isAnimating = true;
                        const isNext = 1;
                        let animations = [];
                        if(!boxesShowing) // Fold boxes
                        {
                            animations = [
                                ...animations,
                                ...showSubBoxes(boxes)
                            ];
                            boxesShowing = true;
                        }
                        
                        animations = [
                            ...animations,
                            ...animateBoxes(boxes, isNext)
                        ];
                        Animated.parallel(animations).start(() => animationInfo.isAnimating = false);
                    }
                }, false, boxes)}
            </React.Fragment>)
        }
      </View>
  );
}

const styles = StyleSheet.create({
    map: {
        height: 300,
        width: "100%",
        borderRadius: 5,
    },
    btn: {
        borderRadius: 10,
        padding: 10,
        borderWidth: 2,
        borderColor: helpers.appColors.red,
        justifyContent: "center",
        alignItems: "center",
    },
    greyBtn: {
        borderRadius: 10,
        padding: 10,
        width: "50%",
        borderWidth: 2,
        borderColor: helpers.appColors.red,
        justifyContent: "center",
        alignItems: "center",
    },
    titleContainer: {
        flexDirection: "row",
    },
    titleBtns: {
        flexDirection: "row",

    },
    titleBtn: {
        padding: 5,
        paddingTop: 0,
    },

    suggestionGradient: {
        width: "100%",
        height: 3,
    },
    suggestion: {
        paddingLeft: 10,
        paddingRight: 10,
        flexDirection: "row",
        height: 39,
        alignItems: "center",
    },
    suggestionInfo: {
        flexDirection: "row",
        flexGrow: 1,
    },
    suggestionAddr: {
        fontSize: 15,
    },
    suggestionCity: {
        fontSize: 15,
        paddingLeft: 10,
        color: colors.textGrey,
    },
    suggestionIcon: {
        height: 10,
        width: 10,
        marginRight: 6,
    },
    inputSuggestions: {
        backgroundColor: "#FFF",
        height: 150,
        width: "100%",
        position: "absolute",
        top: 46,

        borderColor: "rgba(0, 0, 0, 0.15)",
        borderWidth: 1,
        borderTopWidth: 0,

        borderBottomLeftRadius: 5,
        borderBottomRightRadius: 5,
    },


    inputContainer: {
        height: 50,
        width: "100%",
        borderColor: "rgba(0, 0, 0, 0.15)",
        borderWidth: 1,
        borderRadius: 5,
        flexDirection: "row",
        alignItems: "center",
        padding: 10,
    },
    input: {
        flexGrow: 1,
        height: "100%",
        marginRight: 5,
        color: "#000"
    },

    rangeSpacer: {
        backgroundColor: colors.textGrey,
        height: 2,
        flexGrow: 1,
        marginLeft: 5,
        marginRight: 5,
    },
    rangeContainer: {
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        paddingBottom: 6,
    },
    circleBtnSelected: {
        backgroundColor: colors.orange,
        borderWidth: 0,
    },
    circleBtnTextSelected: {
        color: colors.white,
    },
    circleBtnText: {
        fontSize: 15,
        color: colors.textGrey,
        textAlign: "center",
    },
    circleBtn: {
        borderWidth: 2,
        borderColor: colors.textGrey,

        alignItems: "center",
        justifyContent: "center",
        borderRadius: 15,
        padding: 5,
        paddingLeft: 6,
        paddingRight: 6,
        minWidth: 30,
        maxWidth: 100,
        flexGrow: 1,
        
        //height: 30,
        
    },
    title: {
        fontSize: 17,
        color: colors.textGrey,
        paddingBottom: 10,
        flexGrow: 1,
    },
    desc: {
        fontSize: 17,
        paddingBottom: 15,
    },
    container: {
        marginTop: 25,
    },
    box: {
        position: "absolute",
        width: "90%",
        borderRadius: 15,
        marginLeft: "5%",
        backgroundColor: "#FFF",
        padding: 15,
        
    },
});


/*
    Ide til anden animation:
    - Svvarer "forkert" = Dette sker samtidigt:
      * Mørk container height bliver animeret
      * sub-boxes slider ned fra toppen
*/
function animateBoxes(boxes: any[], isNext: boolean)
{
    console.log("Animating boxes", boxes);
    const targetBox = boxes.find(d => isNext ? (d.zIndex._value === boxes.length - 1) : (d.zIndex._value === 0));
    const getzId = (box) => isNext ? (box.zIndex._value + 1) : (box.zIndex._value - 1);
    return [
        Animated.sequence([
            Animated.delay(300),
            Animated.timing(
                targetBox.transform,
                {
                    toValue: {
                        y: 0,
                        x: isNext ? ((width * 0.935) * -1) : ((width * 1.1) * -1),
                    },
                    duration: 400,
                }
            ),
            Animated.parallel([
                /*
                    Updates zIndex for currentBox, and all other boxes.
                */
                Animated.timing(
                    targetBox.zIndex,
                    {
                        toValue: isNext ? 0 : (boxes.length - 1),
                        duration: 10,
                    }
                ),
                ...boxes.filter(box => box.zIndex._value !== targetBox.zIndex._value).map(box => Animated.timing(
                    box.zIndex,
                    {
                        toValue: getzId(box),
                        duration: 10,
                    }
                )),
                /*
                    Update scale for and all other boxes.
                */
            ...boxes.filter(box => box.zIndex._value !== targetBox.zIndex._value).map(box => {
                    return Animated.timing(
                        box.scale,
                        {
                            toValue: animationInfo.scale.func((boxes.length - 1) - getzId(box)),
                            duration: 400,
                        }
                    );
                }),
                /*
                    Update y for and all other boxes.
                */
            ...boxes.filter(box => box.zIndex._value !== targetBox.zIndex._value).map(box => {
                    const  newI = (boxes.length - 1) - (box.zIndex._value + 1);
                return Animated.timing(
                        box.transform,
                        {
                            toValue: {
                                x: 0,
                                y: animationInfo.y.func(newI),
                            },
                            duration: 400,
                        }
                    );
                }),
            ]),
            /*
                Move current box to the back
            */
            Animated.parallel([
                Animated.timing(
                    targetBox.transform,
                    {
                        toValue: {
                            y: animationInfo.y.func(isNext ? (boxes.length - 1) : 0),
                            x: 0,
                        },
                        useNativeDriver: false,
                        duration: 400,
                    }
                ),
                Animated.timing(
                    targetBox.scale,
                    {
                        toValue: animationInfo.scale.func(isNext ? (boxes.length - 1) : 0),
                        duration: 400,
                        
                        useNativeDriver: false,
                    }
                ),
            ])
        ])
    ];
}
