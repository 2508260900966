export default {
    "da-DK": {
      "Daglige handlinger": "Daglige handlinger",
      "point": "point",
      "WRONG_PASS_USR": "Forkert brugernavn, eller kodeord.",
      "Email": "Email",
      "Password": "Password",
      "Sign in": "Sign in",
      "Sign up": "Sign up",
      "Forgot your password?": "Forgot your password?",
      "Code not found": "Code not found",
      "Invitation code": "Invitation code",
      "Code": "Code",
      "Continue": "Continue",
      "SIGNUP_TERMS": "By signing up, you agree to our Terms and Conditions of use.",
      "First name": "First name",
      "Last name": "Last name",
      "Phone": "Phone",
      "Confirm password": "Confirm password",
      "The email is not valid": "The email is not valid",
      "All information must be filled": "All information must be filled",
      "The given passwords must be the same": "The given passwords must be the same",
      "UNKOWN_ERROR": "An unkown error occurred. Contact an administrator if this problem persists.",
      "CANCEL": "Annuller",
      "DELETE": "Slet",
      "DELETE_OBS": "Vil du slette den uden at indsende dataen?",
      "Ingen igangværende rapporter! Du kan åbne en ny ovenover": "Ingen igangværende rapporter! Du kan åbne en ny oven over.",
      "": "",
    },
    en: {},
    ja: {},
};