import React, { useState } from 'react';
import { StyleSheet, Text, View, TouchableOpacity, Image } from 'react-native';
import { FontAwesome as Icon  } from '@expo/vector-icons';
import { LinearGradient } from 'expo-linear-gradient';
import {article} from "../classes/questionTemplate";

import helpers from "../../helpers";

interface Props {
    onlyHeader?: boolean,
    children?: React.ReactNode,
    data: article,
}

const colors = helpers.appColors;
export default function Article(props: Props){
    if(props.onlyHeader)
    {
        return  <>
                    {props.children}
                    <Image 
                    style={styles.newsBg}
                    source={{uri: props.data.image}} />
                    <View style={styles.overlayContainer}>
                        <Text style={[styles.newsTitle, styles.shadow]}>
                            {props.data.title}
                        </Text>
                    </View>
                </>;
    }
    return (
        <View style={styles.newsContent}>
            <View style={[helpers.appStyles.inlineFlex, styles.infoContainer]}>
                <View style={helpers.appStyles.inlineFlex}>
                    <Icon name={"eye"} size={12} color="#000" />
                    <Text style={styles.readersText}>{props.data.views}</Text>
                </View>
                <Text style={styles.dateText}>{props.data.unix.toDateString()}</Text>
            </View>
            <View style={styles.newsBottomPart}>
                <Text style={[styles.newsDesc]}>
                    {props.data.text}
                </Text>
            </View>
        </View>);
};

const styles = StyleSheet.create({
    dateText: {
        fontSize: 11,
    },
    readersText: {
        marginLeft: 5,
        fontSize: 11,
    },
    infoContainer: {
        width: "100%",
        justifyContent: "space-between",
        paddingBottom: 10,
    },
    newsContent: {
        padding: 10,
    },
    newsTitle: {
        fontSize: 28,
        color: colors.white,
        fontWeight: "500",
    },
    overlayContainer: {
        position: "absolute",
        zIndex: 999,
        height: 200,
        width: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.1)",

        borderTopLeftRadius: 30,
        borderTopRightRadius: 30,

        justifyContent: "center",
        paddingLeft: 20,
        
    },
    shadow: {
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 0,
        },
        shadowOpacity: 0.5,
        shadowRadius: 5,
    },
    newsBg: {
        width: "100%",
        height: 200,
        zIndex: 1,
        
        
        borderTopLeftRadius: 11,
        borderTopRightRadius: 11,
    },
});