// const example = [{
//     type: "randomizer",
//     children: [{
//         type: "text",
//         question: "Udfyld dit navn",
//     },{
//         type: "select",
//         question: "Udfyld dit navn",
//         options: ["Ja", "Nej"],
//         correct: "Ja",
//     },]
// }];

import React from "react";
import {View} from "react-native";
import QuestionBox from "./QuestionBox";
import {questionJSONTemplate} from "./questionTemplate";
import {shuffleArr} from "../../helpers";
import * as Location from 'expo-location';


export default class Question {
    visible = true;
    questionID:number = 0;
    question:string = "";
    type:string = "";
    children:Question[] = [];
    
    options:string[] = [];
    correct:string = "";

    ref:React.ReactElement|boolean = false;
    
    functionalTypes: string[] = ["randomizer", "valueChecker", "group", "points", "notNeeded"];
    // Contains the response data
    response:string[] = [];

    responseEvents: ((data: any) => void)[] = [];

    constructor(questionID: number = 0,
                type: string,
                children: Question[] = [],
                question: string = "",
                options: string[] = [],
                correct: string = "",
                response: string[] = [],
                initialized = false)
    {
        this.questionID = questionID || 0;
        this.type = type;
        this.children = children;
        this.question = question;
        this.options = options;
        this.correct = correct;
        this.response = response;
        if(!initialized)
        {
            this.initialFunctionalComponent();
        }
        this.setupBubbleUp();
    }

    setupBubbleUp()
    {
        this.children.map((child, i) => child.responseEvents.push((data) => this.bubbleUp(data, child, i)));
    }

    bubbleUp(data, child, i)
    {
        if(this.functionalTypes.includes(this.type))
        {
            // console.log("Bubble event", data, child);
            if(child.type === "radio" || child.type === "select")
            {
                this.response[i] = child.children.reduce((curr, list) => [...curr, ...list.response], []);
            }else{
                this.response[i] = data;
            }
        }
    }

    toJSON(fullData:boolean = false):questionJSONTemplate
    {
        return {
            questionID: this.questionID,
            question: this.question,
            type: this.type,
            response: this.response,
            correct: this.correct,
            options: this.options,
            children: this.children.map(child => child.toJSON(fullData)),
        };
    }

    initialFunctionalComponent()
    {
        // console.log("Evaluating initial-functional compoonent!", this.type);
        switch(this.type)
        {
            case "randomizer":
                this.children = shuffleArr(this.children);
                return this.children;
            break;
            
            default:
                // console.log("Initial-functional component thing not found", this.type);
                return this.children;
            break;
        }
    }

    handleFunctionalComponent()
    {
        // console.log("Evaluating functional compoonent!", this.type);
        switch(this.type)
        {
            case "points":
            case "valueChecker":
                {
                    const IS_FILLED = this.children.length > 0 && this.children[0].response.length > 0;
                    const CORRECT = IS_FILLED && this.children[0].options.indexOf(this.correct) === Number(this.children[0].response[0]);//this.correct === this.children[0].response[0];// this.children[0].options.indexOf(this.correct) === Number(this.children[0].response[0]);//this.children[0].response[0] === this.correct;
                    this.children.forEach((child, i) => {
                        child.visible = (IS_FILLED && !CORRECT) || i === 0;
                    });
                    return this.children;
                }
            break;
            default:
                // console.log("Functional component thing not found", this.type);
                return this.children;
            break;
        }
    }

    get(
        openModal: (content: React.ReactElement | null, header: React.ReactElement | null, show: boolean) => void, 
        showContent: (content: React.ReactElement | null, show: boolean) => void, 
        openCam: (show: boolean, callback: () => void) => void,
        getLocation: () => Promise<Location.LocationData>,
        triggerEvent: (name: string) => void,
        sheet?: any, // Can only be type Sheet
    ):React.ReactElement
    {
        if(this.functionalTypes.includes(this.type))
        {
            return (<>{this.handleFunctionalComponent().filter(child => child.visible).map(child => child.get(openModal, showContent, openCam, getLocation, triggerEvent, sheet))}</>)
        }else{
            return (
                    <QuestionBox
                        type={this.type}
                        sheet={sheet}
                        question={this.question}
                        options={this.options}
                        questionID={this.questionID}
                        response={this.response[0] || false}
                        responses={this.response}
                        children={this.children}
                        getLocation={getLocation}

                        setResponse={(data: string[]) => {
                            console.log("Got resposne", data);
                            this.response = data;

                            this.responseEvents.map(func => func(data));

                            // this.ref.forceUpdate();
                            triggerEvent("response");
                        }}
                        openModal={openModal}
                        showContent={showContent}
                        openCam={openCam}
                    />
                );
            }
        }
}