import React, { useState } from 'react';
import { StyleSheet, Text, View, TouchableOpacity, ScrollView, TouchableHighlight, ActivityIndicator } from 'react-native';
import { FontAwesome as Icon, Ionicons } from '@expo/vector-icons';
import * as WebBrowser from 'expo-web-browser';

import { useIsFocused } from '@react-navigation/native';

import {baseProps} from "./classes/questionTemplate";
import GradientHeader from "./UI/GradientHeader";

import helpers, {api} from "../helpers";

import Lang from "./locale";
import WindowDimensions from "./classes/Dimensions";


const colors = helpers.appColors;
const { width, height } = WindowDimensions;

interface props extends baseProps {
    
}

interface message {
    id: number,
    read: number,
    systemMessage: number,
    sender: number|null,
    title: string,
    content: string,
    time: string,
    senderName: string,
}

interface state {
    data: message[],
    loading: boolean,
}
export default class InboxPage extends React.Component<props> {
    state:state = {
        data: [],
        loading: true,
    };

    parseUrls(text: string){
        //console.log(text);
        let expression = /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z0-9\u00a1-\uffff][a-z0-9\u00a1-\uffff_-]{0,62})?[a-z0-9\u00a1-\uffff]\.)+(?:[a-z\u00a1-\uffff]{2,}\.?))(?::\d{2,5})?(?:[/?#]\S*)?$/ig;///[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/gi;
        let regex = new RegExp(expression);
        let tempString = text.split(" ");
    
          //console.log(regex.exec(text));
    
        let temp = "";
        let ret = [];
        let j = 0;
        for(let i = 0; i < tempString.length; i++)
        {
          let curr = tempString[i];
          if(!ret[j])
          {
            ret[j] = {
              isLink: false,
              text: "",
            };
          }
          if(curr.match(expression))  
          {
            if(ret[j].text.length > 0)
            {
              j++;
              i--;
              continue;
            }
            ret[j].isLink = true;
            ret[j].text = curr;
    
          }else{
            if(ret[j].isLink)
            {
              j++;
              i--;
              continue;
            }
            ret[j].text = ret[j].text.length > 0 ? (ret[j].text + " " + curr) : curr;
          }
        }
    
        return ret;
    
      }

      openMessage(i: number)
      {
          const target = this.state.data[i];
          if(target)
          {
              if(target.read === 0)
              {
                  api("readInbox", {id: target.id}, this.props.userData.token);
              }
              console.log("Urls", this.parseUrls(target.content));
              this.props.showModal(
                  <View style={{padding: 20}}>
                      <Text style={{fontSize: 28}}>{target.title}</Text>
                      <Text style={styles.date}>{new Date(Number(target.time || 0)).toLocaleDateString()} - {(target.systemMessage === 1 || target.sender === null) ? "System" : target.sender}</Text>
                      <View style={{marginTop: 20}}>
                        {this.parseUrls(target.content).map((d, key) => d.isLink ? <TouchableHighlight onPress={() => WebBrowser.openBrowserAsync(d.text)} key={"link" + key}>
                                                                                    <Text style={{color: "grey"}}>{d.text}</Text>
                                                                                  </TouchableHighlight> : <Text key={key}>{d.text}</Text>)}
                      </View>
                  </View>,
                  <View style={{height: 40, width: "100%"}}>
                      <TouchableOpacity  onPress={() => this.props.showModal(null, null, false)} style={styles.articleClose}>
                          <Ionicons name={"md-close"} size={28} color="#000" />
                      </TouchableOpacity>
                  </View>
              );
          }
      }

    componentDidMount()
    {
        this.getData();
        this.props.navigation.addListener('focus', () => {
            this.getData();
        });
    }

    async getData()
    {
        this.setState({
            loading: true,
        });
        let data = await api("getInbox", {}, this.props.userData.token);
        if(data && !data.error)
        {
            console.log("Inbox data", data);
            this.setState({
                loading: false,
                data: data.data
            });
        }
    }

    render(){
        console.log("Inbox", this.props);
        return (
            <View style={styles.bg}>
                <GradientHeader 
                userData={this.props.userData}
                headerTitle={Lang.t("Indbakke")}
                points={this.props.points || 0}
              />
              <ScrollView contentInset={{bottom: 30}}>
                  <View style={styles.messages}>
                      {
                          this.state.loading ? <ActivityIndicator size="large" style={{paddingTop: 40}}/> :
                            (this.state.data.length > 0 ? this.state.data.map((d, i) => <TouchableOpacity onPress={() => this.openMessage(i)} style={styles.message} activeOpacity={0.5} key={i}>
                            <View style={styles.top}>
                                <Text style={styles.title}>{d.title}</Text>
                                <Text style={styles.date}>{new Date(Number(d.time || 0)).toLocaleDateString()} - {(d.systemMessage === 1 || d.sender === null) ? "System" : d.sender}</Text>
                            </View>
                            <View style={[styles.desc, helpers.appStyles.inlineFlex]}>
                                <Text style={styles.descText}>
                                    {d.content.substring(0, 20)}...
                                </Text>
                                <Icon name="chevron-right" size={18} style={{marginBottom: 25}}/>
                            </View>
                      </TouchableOpacity>) : <Text>{Lang.t("INBOX_EMPTY")}</Text>)
                          
                      }
                  </View>
              </ScrollView>
            </View>
        );
    }
}

//  function InboxPagez(props) {
//     const openMessage = (i:number) => {
//         props.showModal(
//             <View style={styles.messageBody}>
//                 <Text>Message content!</Text>
//             </View>,
//             <View style={{height: 40, width: "100%"}}>
//                 <TouchableOpacity  onPress={() => props.showModal(null, null, false)} style={styles.articleClose}>
//                     <Ionicons name={"md-close"} size={28} color="#000" />
//                 </TouchableOpacity>
//             </View>
//         );
//     };

//   return (
//       <View style={styles.bg}>
//         <GradientHeader headerTitle="Indbakke" userData={props.userData}/>
//         <ScrollView contentInset={{bottom: 30}}>
//             <View style={styles.messages}>
//                 {
//                     [...Array(5)].map((d, i) => <TouchableOpacity onPress={() => openMessage(i)} style={styles.message} activeOpacity={0.5} key={i}>
//                     <View style={styles.top}>
//                         <Text style={styles.title}>Title</Text>
//                         <Text style={styles.date}>5 minutes ago</Text>
//                     </View>
//                     <View style={[styles.desc, helpers.appStyles.inlineFlex]}>
//                         <Text style={styles.descText}>
//                             Oh Mark, Thank you! You’re highly polite, as usual! How are you? oh yeah det er mig tekst skal være her hahah
//                         </Text>
//                         <Icon name="chevron-right" size={18} />
//                     </View>
//                 </TouchableOpacity>)
//                 }
//             </View>
//         </ScrollView>
//       </View>
//   );
// }

const styles = StyleSheet.create({
    messageBody: {
        padding: 10,
    },
    articleClose: {
        position: "absolute",
        right: 10,
        width: 45,
        height: 45,
        zIndex: 999,

        justifyContent: "center",
        alignItems: "center",
    },
    headerText: {
        color: colors.white,
        fontSize: 28,
        fontWeight: "bold",
        padding: 15,
        paddingTop: 37,
        paddingBottom: 21,

    },
    messages: {
        alignItems: "center",
        paddingLeft: 15,
        paddingRight: 15,
    },
    message: {
        width: "100%",
        height: 75,
        backgroundColor: "#FFF",
        borderRadius: 15,

        padding: 10,


        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 10,
        },
        shadowOpacity: 0.1,
        shadowRadius: 10,
        
        justifyContent: "space-evenly",
        marginBottom: 7.5,
        marginTop: 7.5,
    },
    top: {

    },
    title: {
        fontSize: 13,
        marginTop: 10,
    },
    date: {
        fontSize: 11,
        color: colors.textGrey,
    },
    desc: {
    },
    descText: {
        fontSize: 11,
        flexGrow: 1,

        paddingRight: 10,
    },

    bg: {
      width: "100%",
      height: "100%",
      backgroundColor: colors.bgGrey,
    },
    topGradient: {
      width: "100%",
      height: 151,
      justifyContent: "flex-end",

      
      
    },
});
