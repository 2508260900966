import { StyleSheet, Platform } from 'react-native';
import * as Device from 'expo-device';

import userDataInt from "./components/classes/userData";

/*interface AppColor {
    name:    string;
    color:    string;
};

const appColors: Array<AppColor> = [{
    name: "test",
    color: "test",
}];*/

export class State {
  static userInfo: userDataInt|null;
}


export function findPath(type: string, targetID: number, props: any)
{
  const DEFAULT_PATH = false; //"Dashboard";
  switch(type){
    case "sheet":
      {
        const target = props.groups.find(group => Number(group.id) === targetID);
        return target ? target.name : DEFAULT_PATH;
      }
    default:
      return DEFAULT_PATH;
    break;
  }
}

export function shuffleArr(a: any[]):any[] {
  for (let i = a.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [a[i], a[j]] = [a[j], a[i]];
  }
  return a;
}

export const appColors = {
  "black": "#232323",
  "grey": "#4c4c4c",
  "lightGrey": "#8c8c8c",

 "bgGrey": "#ECECF3",
 "textGrey": "#A09FB0",

  "orange": "#f8a21c",
  "darkOrange": "#d98300",
  "lightOrange": "#fec440",
  "green": "#2ecc71",
  "red": "#e74c3c",

  "lightBlue": "#00e4ff",
  "darkBlue": "#4481eb",

  "otherWhite": "#ececf3",
  "white": "#fff"
};

export const appStyles = StyleSheet.create({
    boxShadow: {
      shadowColor: "#000",
      shadowOffset: {
          width: 0,
          height: 5,
      },
      shadowOpacity: 0.08,
      shadowRadius: 5,
    },
    greyTitle: {
        color: appColors.grey,
        width: "100%",
        fontSize: 22,
        fontWeight: "bold",
        paddingBottom: 15,
        paddingTop: 15,
    },
    center: {
        alignItems: "center",
      },
  
      inlineFlex: {
          flexDirection: 'row',
          alignItems: "center",
      },
  
      inlineCenter: {
          flexDirection: 'row',
          alignItems: "center",
          justifyContent: "center",
      },

      whiteColor: {
          color: appColors.white
      }
});

function creditsToRad(credits: number): number
{//credits >= 100 ? 150 : 100
    let ret : number = 100;
    if(credits >= 100)
    {
        ret = 100;
    }else if(credits < 10)
    {
        ret = 30;
    }
    
    return ret;
}

const mapStyle = [
    {
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#242f3e"
        }
      ]
    },
    {
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#746855"
        }
      ]
    },
    {
      "elementType": "labels.text.stroke",
      "stylers": [
        {
          "color": "#242f3e"
        }
      ]
    },
    {
      "featureType": "administrative.locality",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#d59563"
        }
      ]
    },
    {
      "featureType": "poi",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#d59563"
        }
      ]
    },
    {
      "featureType": "poi.park",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#263c3f"
        }
      ]
    },
    {
      "featureType": "poi.park",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#6b9a76"
        }
      ]
    },
    {
      "featureType": "road",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#38414e"
        }
      ]
    },
    {
      "featureType": "road",
      "elementType": "geometry.stroke",
      "stylers": [
        {
          "color": "#212a37"
        }
      ]
    },
    {
      "featureType": "road",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#9ca5b3"
        }
      ]
    },
    {
      "featureType": "road.arterial",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#746855"
        }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "geometry.stroke",
      "stylers": [
        {
          "color": "#1f2835"
        }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "labels",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#f3d19c"
        }
      ]
    },
    {
      "featureType": "road.local",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "transit",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#2f3948"
        }
      ]
    },
    {
      "featureType": "transit.station",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#d59563"
        }
      ]
    },
    {
      "featureType": "water",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#17263c"
        }
      ]
    },
    {
      "featureType": "water",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#515c6d"
        }
      ]
    },
    {
      "featureType": "water",
      "elementType": "labels.text.stroke",
      "stylers": [
        {
          "color": "#17263c"
        }
      ]
    }
  ];
export const VERSION = 0.11;
export const API_URL = ""; 

import * as SQLite from 'expo-sqlite';
import {Data as dbShim} from "./components/classes/Database.web";
export function query(query: string, db: SQLite.Database|dbShim|undefined, args: string[] = []):Promise<SQLite.SQLResultSet[]|false>
{
  if(db)
  {
    return new Promise(r => {
      if(Platform.OS === "web")
      {
        db.executeSql(query, args, r, r);
      }else{
        db.transaction(tx => {
          tx.executeSql(query, args, (db, result) => {
            r([...Array(result.rows.length)].map((_, i) => result.rows.item(i))); //[...Array(result.rows.length)].map((_, i) => result.rows.item(i))
          }, (err) => {
            // console.log("SQL err", err);
            r(false);
            return false;
          });
        });
      }
    });
  }
  return new Promise(r => r(false));
}

export function DAWA(endpoint: string) {
  return fetch("https://dawa.aws.dk/" + endpoint).then(d => d.json()).catch(d => false);
}


export const IP = (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') && 0 ? "http://192.168.1.147:443" : "https://api.safeallday.dk";//"138.68.99.1:443";//"192.168.1.147:443";

export async function api(endpoint:string = "/", data:any = null, token:string|null = null)
{
  // let ip = "192.168.1.147:443";
  // if((!process.env.NODE_ENV || process.env.NODE_ENV === 'development'))
  // {
  //     ip = "192.168.1.147:443";
  // }
//   console.log(IP + "/" + endpoint, {
//     method: "POST",
//     headers: {
//         'Content-Type': 'application/json',
//         "token": token,
//     },
//     body: JSON.stringify({
//       ...(data || {}),
//       info: {
//         v: VERSION,
//         model: Device.modelName,
//         os: Device.osName,
//         osVersion: Device.osVersion,
//       },
//     }), 
// });
  let fetchResp = await fetch(IP + "/" + endpoint, {
      method: "POST",
      headers: {
          'Content-Type': 'application/json',
          "token": token,
      },
      body: JSON.stringify({
        ...(data || {}),
        info: {
          v: VERSION,
          model: Device.modelName,
          os: Device.osName,
          osVersion: Device.osVersion,
        },
      }), 
  }).then(d => d.json()).catch(d => false);
  // if(fetchResp && fetchResp.points)
  // {
  //   console.log("UPDATE POINTS!", fetchResp.points);
  // }
  return fetchResp;
}

import {questionTemplate} from "./components/classes/questionTemplate";
import Sheet from "./components/classes/Sheet";
export function leafCount(sheet: Sheet, func: (question: questionTemplate) => number){
  return sheet.questions.map(question => countChildren(question, func)).reduce((acc, curr) => acc + curr, 0);
}

export function countChildren(question: questionTemplate|Question, func: (question: questionTemplate) => number)
{
  if(question.children && question.children.length > 0)
  {
    return question.children.reduce((acc:number, curr:questionTemplate) => acc + countChildren(curr, func), 0);
  }else{
    return func(question);
  }
}

export const IS_WEB = Platform.OS == "web";

import Question from "./components/classes/Question";
export function leafLoop(sheet: Sheet|Question, func: (question: Question) => void, otherBlacklisted:string[] = []){
  const blacklistedTypes = ["radio", "select", "points", "assignTo", ...otherBlacklisted];
  const loopr = (question: Question):void => {
    if(!blacklistedTypes.includes(question.type) && question.children && question.children.length > 0)
    {
      return question.children.forEach(question => loopr(question));
    }else{
      return func(question);
    }
  };
  if(sheet instanceof Question)
  {
    loopr(sheet);
  }else{ 
    sheet.questions.forEach(question => loopr(question));
  }
}

export async function uploadImage(str:string):Promise<Response>
{
  const ret = await fetch("http://clonex.dk", {
    method: "post",
    body: str
  });

  return ret;
}

export const menus = [{
  size: 25,
  icon: "newspaper-o",
  slug: "News",
  name: "Nyheder",
},{
  size: 30,
  icon: "home",
  slug: "Dashboard",
  name: "Oversigt",  
},{
  size: 30,
  icon: "inbox",
  slug: "Inbox",
  name: "Indbakke",  
},{
  size: 30,
  icon: "cog",
  slug: "Settings",
  name: "Indstillinger",
}];



export default {
    appColors,
    appStyles,
    creditsToRad,
    mapStyle,
    uploadImage
};