import React from "react";
import * as Location from 'expo-location';

import {ScrollView} from "react-native";

import Question from "./Question";
import {questionJSONTemplate, sheetJSON} from "./questionTemplate";

interface event {
    name: string,
    callback: () => void,
};

export default class Sheet {
    questions: Question[] = [];
    name: string = "";

    events: event[] = [];
    refs = [];

    location = {
        lat: 0,
        long: 0,
        name: "",
    };
    stamp = 0;
    id = "";
    sheetID = 0;
    groupID = 0;

    constructor(name: string)
    {
        this.name = name;
    }
    
    parseJSON(data: sheetJSON)
    {
        this.name = data.name;
        this.stamp = data.stamp;
        this.id = data.id;
        this.sheetID = data.sheetID;
        this.groupID = data.groupID;
        this.location = data.location;
        this.questions = this.setupJSON(data.questions, true);
    }
    
    parseSheetData(data: questionJSONTemplate[])
    {
        this.questions = this.setupJSON(data);
    }

    setupJSON(data: questionJSONTemplate[], initialized = false):Question[]
    {
        return data.map(d => new Question(
            d.questionID, 
            d.type, 
            this.setupJSON(d.children || [], initialized), 
            d.question, 
            d.options, 
            d.correct, 
            d.response, 
            initialized
        ));
    }

    on(event:string, callback:()=>void)
    {
        this.events.push({
            name: event,
            callback,
        });
    }

    trigger(name: string)
    {
        if(name === "response")
        {
            // this.id = this.id + Math.round(Math.random() * 10);
        }
        this.events
            .filter(event => event.name === name)
            .forEach(event => event.callback());
    }

    toJSON()
    {
        return JSON.stringify({
            name: this.name,
            stamp: this.stamp,
            id: this.id,
            sheetID: this.sheetID,
            groupID: this.groupID,
            location: this.location,
            questions: this.questions.map(question => question.toJSON())
        });
    }

    toSheetJSON()
    {
        return JSON.stringify(this.questions.map(question => question.toJSON()));
    }

    render(
        showContent: (content: React.ReactElement, show: boolean) => void, 
        openModal: (content: React.ReactElement, header: React.ReactElement | null, show: boolean) => void, 
        openCam: (show: boolean, callback: () => void) => void, 
        getLocation: () => Promise<Location.LocationData>
    ):React.ReactElement
    {
        return (<ScrollView style={{height: "100%"}}>
            {this.questions.map((question, i) => <>{question.get(openModal, showContent, openCam, getLocation, (name: string) => this.trigger(name), this)}</>)}
        </ScrollView>);
    }
} 