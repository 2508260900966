import alasql from "alasql";

export class Data {
    data = {};
    // db = alasql.Database();
    name = "temp";
    ready = false;

    queue = [];

    constructor(data: string, name?: string)
    {
        if(name)
        {
            this.name = name;
        }
        this.initDB();
        // this.parseData(data);
    }

    async initDB()
    {
        await alasql.promise('CREATE INDEXEDDB DATABASE IF NOT EXISTS `' + this.name + '`;ATTACH INDEXEDDB DATABASE `' + this.name + '`;USE `' + this.name + '`;');
        this.ready = true;
        for(let i = 0; i < this.queue.length; i++)
        {
            const {query, args, callback, errorFunc} = this.queue[i];
            await this.executeSql(query, args, callback, errorFunc);
        }
        this.queue = [];
        // alasql(`CREATE INDEXEDDB DATABASE IF NOT EXISTS geo; ATTACH INDEXEDDB DATABASE geo; USE geo;`);
    }

    parseData(temp: string)
    {
        try {
            const d = JSON.parse(temp);
            this.data = d;
        } catch (error) {
            this.data = {};
        }
        Object.keys(this.data).forEach(key => {
            // this.db.tables[key].data = this.data[key];
        });
        // this.db.tables = this.data;
    }

    transaction(getter){
        getter(this);
    }

    async executeSql(query, args, callback, errorFunc)
    {
        // return;
        try {
            if(!this.ready)
            {
                this.queue.push({
                    query,
                    args,
                    callback,
                    errorFunc
                });
            }else{
                const temp = await alasql.promise(query, args);
                callback(temp);
            }
        } catch (err) {
            if(errorFunc)
            {
                errorFunc(err);
            }
        }
    }

    saveDB()
    {
        const data = Object.keys(this.db.tables).reduce((obj, key) => {
            obj[key] = this.db.tables[key].data;
            
            return obj;
        }, {});
        localStorage.setItem(this.name, JSON.stringify(data));
    }

}

export default class DB {
    static data;

    static openDatabase(name: string) {
        const temp = localStorage.getItem(name);
        
        return new Data(temp || "{}", name);
    }
};