import React, {useRef} from 'react';
import { StyleSheet, Text, View, TextInput, Alert, KeyboardAvoidingView, Platform, Image } from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';

import AnimatedButton from "./UI/AnimatedButton";
import {api, appColors as colors, appStyles} from "../helpers";

import Lang from "./locale";
import WindowDimensions from "./classes/Dimensions";



// const colors = helpers.appColors;
const { width, height } = WindowDimensions;

export default function SignUpPage(props) {
    const refs = {
        btn: useRef(false),
        first: useRef(false),
        last: useRef(false),
        phone: useRef(false),
        mail: useRef(false),
        pass: useRef(false),
        confirm: useRef(false),
    };

    const whatToFocus = () => {
        const target = Object.keys(refs).filter(key => key !== "btn").find(key => {
            const curr = refs[key];
            return curr.current && (!curr.current._lastNativeText || curr.current._lastNativeText.length === 0);
        });

        if(target)
        {
            refs[target].current.focus();
        }else{
            refs.btn.current.startLoad();
        }
    }
    setTimeout(() => whatToFocus(), 1100);
  return (
      <View style={styles.bg}>
         <LinearGradient
            colors={[colors.orange, colors.red]}
            style={styles.topBg}
        />
        <KeyboardAvoidingView behavior="padding">
            <View style={styles.container} onLayout={() => null}>
                <View style={[styles.containerTop, appStyles.inlineCenter]}>
                    <Text style={styles.containerTopText}>{Lang.t("Sign Up")}</Text>
                </View>
                <View style={styles.inputContainer}>
                    <TextInput style={styles.input} validator={(val:string) => val && val.length > 0} ref={refs.first} autoCompleteType="name" placeholder={Lang.t("First Name")} placeholderTextColor="#a09fb0" onSubmitEditing={whatToFocus}/>
                    <TextInput style={styles.input} validator={(val:string) => val && val.length > 0} ref={refs.last} placeholder={Lang.t("Last Name")} placeholderTextColor="#a09fb0" onSubmitEditing={whatToFocus}/>
                    <TextInput style={styles.input} keyboardType="phone-pad" validator={(val:string) => val && val.length > 0} ref={refs.phone} autoCompleteType="tel" placeholder={Lang.t("Phone")} placeholderTextColor="#a09fb0" onSubmitEditing={whatToFocus}/>
                    <TextInput style={styles.input} keyboardType="email-address" autoCompleteType="email" validator={(val:string) => val && val.length > 0 && val.includes("@") && val.includes(".")} autoCapitalize="none" ref={refs.mail} placeholder={Lang.t("Email")} placeholderTextColor="#a09fb0" onSubmitEditing={whatToFocus}/>
                    <TextInput style={styles.input} autoCompleteType="password" secureTextEntry={true} validator={(val:string) => val && val.length > 0} ref={refs.pass} placeholder={Lang.t("Password")} placeholderTextColor="#a09fb0" onSubmitEditing={whatToFocus}/>
                    <TextInput style={styles.input} autoCompleteType="password" secureTextEntry={true} validator={(val:string) => val && val.length > 0} ref={refs.confirm} placeholder={Lang.t("Confirm Password")} placeholderTextColor="#a09fb0" onSubmitEditing={whatToFocus}/>
                </View>
                <View style={styles.btnContainer}>
                    <AnimatedButton title={Lang.t("Sign up")} width={width - (28 * 2) - (30 * 2)} onPress={async () => {
                        const check = Object.keys(refs).filter(key => key !== "btn").find(key => {
                            const curr = refs[key];
                            return curr.current && !curr.current.props.validator(curr.current._lastNativeText);
                        });

                        if(check)
                        {
                            if(check === "mail")
                            {
                                Alert.alert("Safeallday", Lang.t("The email is not valid"));
                            }else{
                                Alert.alert("Safeallday", Lang.t("All information must be filled"));
                            }
                            refs[check].current.focus();
                        }else if(refs.confirm.current._lastNativeText !== refs.pass.current._lastNativeText)
                        {
                            Alert.alert("Safeallday", Lang.t("The given passwords must be the same"));
                        }else{
                            const data = await api("signUp", {
                                first: refs.first.current._lastNativeText || "",
                                last: refs.last.current._lastNativeText || "",
                                mail: refs.mail.current._lastNativeText || "",
                                pass: refs.pass.current._lastNativeText || "",
                                phone: refs.phone.current._lastNativeText || "",
                                code: props.route.params.code || "",
                            });
                            if(!data.error)
                            {
                                props.navigation.push("Login", {
                                    mail: refs.mail.current._lastNativeText || "",
                                    pass: refs.pass.current._lastNativeText || "",
                                    ...props.route.params,
                                });
                            }else{
                                Alert.alert(Lang.t("UNKOWN_ERROR"));
                            }
                        }
                        refs.btn.current.stopLoad();
                    }} ref={refs.btn}/>
                </View>
            </View>
        </KeyboardAvoidingView>
        <View>
            <Text style={styles.subText}>
                {Lang.t("SIGNUP_TERMS")}
            </Text>
        </View>
        
      </View>
  );
}

const styles = StyleSheet.create({
    subText: {
        color: "#A09FB0",
        textAlign: "center",
        paddingTop: 24,
    },
    btnText: {
        fontSize: 18,
        color: "#FFF"
    },
    btnContainer: {
        shadowColor: colors.orange,
        shadowOffset: {
            width: 0,
            height: 0,
        },
        shadowOpacity: 0.4,
        shadowRadius: 16.00,
    },
    btn: {
        width: "100%",
        height: 60,
        marginTop: 40,
        borderRadius: 15,

        justifyContent: "center",
        alignItems: "center",

    },


    inputContainer: {
        marginTop: 15
    },
    input: {
        width: "100%",
        height: 50,

        marginBottom: 20,
        paddingLeft: 15,

        borderBottomWidth: 1,
        borderBottomColor: "#ECECF3",
    },
    containerTopText: {
        fontSize: 35,
        color: "#a09fb0",
    },
    containerTop: {
        marginBottom: 8,
    },
    container: {
        backgroundColor: "#FFF",
        width: "100%",
        borderRadius: 10,
        zIndex: 1,
        padding: 28,

        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 10,
        },
        shadowOpacity: 0.04,
        shadowRadius: 6.00,
    },
    topBg: {
        height: height * 0.7,
        width,
        position: "absolute",
        top: 0,
        left: 0,
        zIndex: 0,
    },
    
  bg: {
    backgroundColor: "#ECECF3",
    width: "100%",
    height: "100%",
    padding: 30,

    justifyContent: "center",
},
});
