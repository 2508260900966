import React, { Component } from "react";
import {View, Animated, StyleSheet, Easing} from "react-native";
import WindowDimensions from "../classes/Dimensions";

import helpers from "../../helpers";
const { width, height } = WindowDimensions;

interface State {
    credits: number,
    tempCredits: number,
}

interface Props {
    amount: number,
    callback?(val: number): void,
    animator?(func: ((val: number) => void)):void,
}

export default class CreditsAnimator extends Component<Props, State> {
    animating = false;
    lastCoin:number = 0;

    constructor(props){
        super(props);
        this.state = {
            credits: props.amount || 0,
            tempCredits: 0,
        };
    }

    componentDidMount()
    {
        if(this.props.animator)
        {
            this.props.animator((number) => this.fireAnimationAsync(number));
        }
    }

    componentDidUpdate()
    {
        if(this.props.animator)
        {
            // this.props.animator((number) =>this.fireAnimation(number));
        }
    }
    
    // Make async?? Return promise
    fireAnimation(amount: number)
    {
        this.setState({
            credits: amount
        });

        const rad = helpers.creditsToRad(amount);
        const creditsAnimation = {
            outTime: 4 * rad,
            inTime: 12 * rad,
        };

        return creditsAnimation.outTime + creditsAnimation.inTime;
    }

    resolver = null;
    fireAnimationAsync(amount: number)
    {
        this.setState({
            credits: amount
        });

        return new Promise(r => this.resolver = r);
    }

    shouldComponentUpdate(props, state)
    {
        return state.credits !== props.amount || state.credits !== this.state.credits;//this.state.credits === state.credits && this.state.credits !== this.props.amount && this.props.amount > 0 && !this.animating;
    }

    // componentDidUpdate(props, state)
    // {
    //     if(this.state.credits === state.credits && this.state.credits !== this.props.amount && this.props.amount > 0 && !this.animating)
    //     {
    //         this.setState({
    //             credits: this.props.amount
    //         });
    //     }
    // }



    randPos = (centerX: number, centerY: number , radius: number = 50) => {
        let pt_angle = Math.random() * 2 * Math.PI;
        let pt_radius_sq = Math.random() * radius * radius;
        let pt_x = Math.sqrt(pt_radius_sq) * Math.cos(pt_angle);
        let pt_y = Math.sqrt(pt_radius_sq) * Math.sin(pt_angle);

        return {
            x: centerX + pt_x,
            y: centerY + pt_y,
        }
    }

    render(){
        const credits = this.state.credits;
        const rad = helpers.creditsToRad(credits);
        const creditsAnimation = {
            outTime: 4 * rad,
            inTime: 12 * rad,
        };
        let creditsDone = 0;
        return ( <View key={credits} style={{zIndex: 9999, height: 1, width: 1}}>
                   {
                       [...Array(credits)].map((d, i) =>  {
                        this.animating = true;
                        const target = {
                            x: -11,//width - 27.5, 
                            y: -3//75
                          };
                          let cord = this.randPos(target.x, target.y, rad);
                          const test = new Animated.ValueXY(target);
                          Animated.timing(test, {
                              toValue: cord,
                              easing: Easing.sin,
                              duration: creditsAnimation.outTime,
                              useNativeDriver: true,
                            }).start(({finished}) => {
                                setTimeout(() => {
                                    Animated.timing(test, {
                                        toValue: target,
                                        duration: creditsAnimation.inTime,
                                        useNativeDriver: true,
                                    }).start(({finished}) => {
                                        // setCredits(0);
                                        // this.props?.callback(1);
                                       
                                        creditsDone++;
                                        if(creditsDone == credits)
                                        {
                                            const dateDiff = Date.now() - this.lastCoin;
                                            if(dateDiff > 2000)
                                            {
                                                this.props?.callback(credits);
                                                this.setState({
                                                    credits: 0
                                                });
                                                creditsDone = 0;
                                                this.animating = false;
                                                this.lastCoin = Date.now();
                                                if(this.resolver)
                                                {
                                                    this.resolver(false);
                                                }
                                            }
                                        }
                                        // setCreditsText(credits + creditsText);
                                    });
                                }, 100);
                            });
                        return (<Animated.View style={[styles.animatedCredits, {transform: test.getTranslateTransform()}]} key={i}/>);
                      })
                  }
                   </View>);
    }
}

const styles = StyleSheet.create({
    animatedCredits: {
        position: "absolute", 
        zIndex: 999,
        backgroundColor: helpers.appColors.darkOrange,
        borderColor: "yellow",
        borderWidth: 2,
        borderRadius: 7.50,
        height: 7,
        width: 7,
    },
});